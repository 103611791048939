@import '@fontsource/roboto-slab/index.css';
@import '@fontsource/roboto-slab/100.css';
@import '@fontsource/roboto-slab/200.css';
@import '@fontsource/roboto-slab/300.css';
@import '@fontsource/roboto-slab/500.css';
@import '@fontsource/roboto-slab/600.css';
@import '@fontsource/roboto-slab/700.css';
@import '@fontsource/roboto-slab/800.css';
@import '@fontsource/roboto-slab/900.css';
@import '@fontsource/montserrat';
@import '@fontsource/montserrat/100.css';
@import '@fontsource/montserrat/200.css';
@import '@fontsource/montserrat/300.css';
@import '@fontsource/montserrat/500.css';
@import '@fontsource/montserrat/600.css';
@import '@fontsource/montserrat/700.css';
@import '@fontsource/montserrat/800.css';
@import '@fontsource/montserrat/900.css';

$robot_font: 'Roboto Slab';
$montserrat: 'Montserrat';
$select_color: #3fc6ad;
$warn_color: #b23c59;

.box_shadow {
  box-shadow: 0px 1px 8px #0000001a;
}

.rounded_3 {
  border-radius: 10px;
}

* {
  font-family: $robot_font;
}

.wrap_100 {
  height: 100vh;
}

.cursor {
  cursor: pointer;
}

.pagination {
  width: 200px;
}

.sign_card {
  width: 300px;
  border-radius: 0.5rem;
  .card-header {
    background-color: $select_color;
    color: white;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
}

.left_side {
  width: 280px;
  min-height: calc(100vh - 51px);
  padding-top: 1rem;
  box-shadow: 2px 4px 8px rgb(0 0 0 / 10%);

  .menu {
    li {
      list-style: none;
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
      font-weight: 400;

      &.selected {
        font-weight: 700;
      }
    }
  }
}

.dashboard_video {
  &.w-100 {
    .modal-dialog {
      max-width: 88%;
    }
  }

  .modal-dialog {
    max-width: 600px;
  }

  .closeBtn {
    position: absolute;
    top: 5px;
    right: -75px;
  }

  .modal-content {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 8px #00000040;
    border-radius: 10px;
    border: 0;

    .modal-header {
      background: #3fc6ad 0% 0% no-repeat padding-box;
      border-radius: 10px 10px 0px 0px;
      color: white;
      font-weight: 300;
      font-size: 1.7rem;
      justify-content: center;

      .modal-title {
        font-weight: 400;
        font-size: 1.7rem;
        text-align: center;
      }
    }
  }

  .youtube {
    background-color: #c4c4c4;
    width: 509px;
    height: 285px;
    border-radius: 10px;

    img {
      display: block;
    }
  }
}

.bg_warn {
  background-color: $warn_color;
  border: 2px solid $warn_color;
  color: white;

  &:disabled {
    background-color: gray;
    border: 2px solid gray;
  }
}

.percent_txt {
  font-weight: bold;
  width: 3.5rem;
  text-align: right !important;
  display: inline-block;
}

.label_txt {
  width: 9rem;
  text-align: right;
}

.saveBtn:disabled {
  background-color: gray;
  border-color: gray;
}
.loginBtn {
  background-color: $select_color;
  border-color: $select_color;
}
.header_logo {
  color: #3fc6ad;
  margin-left: 1rem;
  font-family: $robot_font;
  font-weight: 900 !important;
}
.tab-content {
  padding: 1rem;
  padding-top: 0 !important;
  border: 1px solid #dee2e6;
  border-top: 0 !important;
  .tab-pane {
    padding-top: 1rem;
  }
}
.form-check-input:checked {
  background-color: #3FC6AD !important;
  border-color: #3FC6AD !important;
}
.btn-success {
  background-color: #3FC6AD !important;
  border-color: #3FC6AD !important;
}